.about-us {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    /* background-image: url(https://chillsairconditioning.com/wp-content/uploads/2023/07/How-Long-Do-Commercial-HVAC-Systems-Last-.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
  }

  .column h5{
    font-size: 15px;
    color:#007bff;
    margin-bottom: 1%;
    
  }
  h5{
    font-size: 15px;
    color:#007bff;
    margin-bottom: 1%;
    
  }

  .column {
      display: flex;
      flex-direction: column; /* Adjust to column for stacking elements */
      align-items: center;
      justify-content: space-between;
      padding: 1px;
      /* color: white; */
      margin: 5px; /* Add margin for spacing */
      width: 45%;
      background-color: rgb(252, 206, 155);
      /* border-radius: 42px; */
  }

  .row {
    /* background-color: #98a6e4; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;
    /* color: white; */
  }

  h1{
    color: white;
  }

  /* AboutUs.css */
.frontpageimage {
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.container.content {
  margin-top: 30px;
}

.plan {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.plan:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.plan-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #07689f;
  text-align: center;
  position: relative;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 50px 50px;
}

.plan-title i {
  font-size: 5rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  color: #555;
  filter: brightness(0.8) saturate(2);
  animation: shine 2s infinite alternate;
  transform-origin: center;
}

.plan-title i.bronze {
  color: #cd7f32;
}

.plan-title i.silver {
  color: #c0c0c0;
}

.plan-title i.gold {
  color: #ffd700;
}

@keyframes shine {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.plan-price {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: #dc3545;
}

.plan-description {
  font-family: 'Lato', sans-serif;
  margin-bottom: 20px;
}

.plan-features {
  margin-bottom: 20px;
}

.plan-feature {
  list-style-type: none;
  padding: 5px 0;
  font-family: 'Open Sans', sans-serif;
}

.container.comparison-table {
  margin-top: 30px;
}

.comparison-table table {
  width: 100%;
  border-collapse: collapse;
}

.comparison-table th,
.comparison-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.comparison-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.comparison-table td:first-child {
  text-align: left;
  font-weight: bold;
}


/* AboutUs.css */

/* Add this style to ensure table borders are visible */
.comparison-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #fff; /* Add a white border to separate table from background */
}

/* Override Bootstrap table cell styles */
.comparison-table th,
.comparison-table td {
  padding: 10px;
  border: 1px solid #ccc; /* Add a border color for cells */
  text-align: center;
  color: #f8f9fa
}

/* Override Bootstrap table header styles */
.comparison-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

/* Override Bootstrap table first column styles */
.comparison-table td:first-child {
  text-align: left;
  font-weight: bold;
}
