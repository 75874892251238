
.BookingPage-container {
  /* background-image: url('https://marketplace.canva.com/EAD2962NKnQ/2/0/1600w/canva-rainbow-gradient-pink-and-purple-virtual-background-_Tcjok-d9b4.jpg'); */
  padding: 20px;
  background-color: #98a6e4;
  text-align: center;
  width: 500px;
  height: 500px;
  margin: 50px auto; /* Center horizontally and add 50px margin at the top */
  font-family: Georgia, 'Times New Roman', Times, serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px; /*Rounded corners */
}

  .BookingPage-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .BookingPage-label {
    font-size: 1.2rem;
    color: #333;
  }
  
  .BookingPage-input {
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding: 5px;
    width: auto;
    font-size: 1rem;
    order: 1px solid #ccc; /* Add a border */
    border-radius: 4px; /* Rounded corners */
    color: #333; /* Default text color */
    text-align: left;
    
  }
  
  .BookingPage-input:focus {
    color: #000; /* Text color when focused (user typing) */
  }
  
  
  .BookingPage-datepicker {
    border: 1px solid #412424;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    font-size: 1rem;
  }
  
  .BookingPage-select {
    padding: 10px;
    width: 100%;
    font-size: 1rem;
  }
  
  .BookingPage-textarea {
    padding: 10px;
    width: 100%;
    font-size: 1rem;
    border: 1px solid #ccc; /* Add a border */
    border-radius: 4px; /* Rounded corners */
    color: #333; /* Default text color */
  }
  .BookingPage-textarea:focus {
    color: #000; /* Text color when focused (user typing) */
  }
  .BookingPage-button {
    background-color: #1a3dd8;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
  }
  
  .BookingPage-button:hover {
    background-color: #0e2756;
  }
  


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  background-color: #baa3dfe1;
  /* border: 1px solid #ccc; */
  padding: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 30px; 
}

.popup-content {
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}

.close:hover {
  color: #000;
}
