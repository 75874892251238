.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make sure the content stretches to at least the full viewport height */
}

body {
    background-color: #000000; /* Replace with your desired background color */
    margin: 0; /* Remove default margin to ensure full coverage */
    padding: 0; /* Remove default padding */

  }
  


  @media (prefers-reduced-motion: no-preference) {
    .header-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .header-nav {
    margin-right: 20px;
  }
  
  .nav-link {
    text-decoration: none;
    color: white;
    margin-left: 20px;
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }  
  
  
  
  .contact-info{
    border-radius: 4px; /* Rounded corners */
    background-image: url('https://marketplace.canva.com/EAD2962NKnQ/2/0/1600w/canva-rainbow-gradient-pink-and-purple-virtual-background-_Tcjok-d9b4.jpg');
    padding: 20px;
    text-align: center;
    width: 300px;
    height: 150px;
    margin: 50px auto; /* Center horizontally and add 50px margin at the top */
    font-family: Georgia, 'Times New Roman', Times, serif;
    /* display: flex;
    flex-direction: column; */
    justify-content: center;
    align-items: center;
    border-radius: 40px; 
  }
