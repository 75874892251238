.header {
  background-color: #002f6c; /* Same as footer */
  color: white;
  padding: 10px 0; /* Similar padding as footer */
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-container {
  max-width: 1200px; /* Similar max-width as footer */
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-link {
  display: flex;
  align-items: center;
}

.header-logo img {
  max-width: 90px;
}

.header-nav {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #00ff99; /* Match the phone number color in the footer */
  text-decoration: none;
  margin: 0 10px;
}

.nav-link:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #7ed321; /* Match the button color in the footer */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #002f6c; /* Match the background color */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content .nav-link {
  color: #00ff99; /* Match the phone number color in the footer */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content .nav-link:hover {
  background-color: #7ed321; /* Match the button hover color */
}

.dropdown:hover .dropdown-content {
  display: block;
}
