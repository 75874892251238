html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif; /* Ensuring a clean font for the body */
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligning text to the left */
  background-size: cover;
  background-position: center;
  height: 400px;
  color: white;
  padding: 20px;
  background-image: url('./images/HVAC-system.jpg'); /* Fallback for the image */
}

.hero-content {
  max-width: 50%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Adding a dark overlay for readability */
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero-buttons {
  display: flex;
  gap: 10px;
}

.hero-button {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border: 2px solid white; /* Outline button */
  cursor: pointer;
  font-size: 1rem;
}

.hero-button:hover {
  background-color: white;
  color: #002f6c; /* Matching the footer/header color */
}

.FrontPage-container {
  background: white; /* Setting background to white */
  padding: 20px; /* Adding padding for spacing */
  display: flex;
  justify-content: center; /* Centering content */
  align-items: center; /* Centering content */
  flex-direction: column; /* Stacking content vertically */
}

.intro-block {
  text-align: center; /* Centering text */
  max-width: 800px; /* Limiting the width of the text block */
  margin-bottom: 20px; /* Adding some space below */
}

.FrontPage-title {
  font-size: 2.5rem; /* Responsive font size */
  color: #002f6c; /* Matching the footer/header color */
  margin-bottom: 20px;
}

.FrontPage-subtitle {
  font-size: 1.5rem; /* Responsive font size */
  color: #7ed321; /* Complementary color */
  font-weight: 700;
  margin-bottom: 20px;
}

.FrontPage-content {
  font-size: 1.2rem; /* Responsive font size */
  color: #333333; /* Darker color for readability */
  font-weight: 400;
  margin-bottom: 20px;
}

.info-container {
  background-color: #f5f5f5; /* Light grey background for contrast */
  padding: 20px;
  border-radius: 10px; /* Rounded corners for a softer look */
  max-width: 800px; /* Limiting the width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
}

.info-container h2 {
  color: #002f6c; /* Matching the header/footer color */
  margin-bottom: 10px;
}

.info-container p {
  color: #333333; /* Darker color for readability */
  margin-bottom: 15px;
}
